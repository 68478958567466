import { apiGetTrialLink } from '@/api/users';

export default defineNuxtRouteMiddleware(async () => {
  try {
    const trialLinkCode = await apiGetTrialLink();

    if (trialLinkCode) {
      setTrialCode(trialLinkCode);
    }
  } catch (e) {
    console.log(e);
  }
  return navigateTo('/signup');
});
